import * as React from "react";
import MainLayout from "../components/layout/MainLayout";
import { GoLocation } from "react-icons/go";
import { Helmet } from "react-helmet";

const AboutMe = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>About me | Husen Lokhandwala</title>
        <meta
          name="description"
          content="MERN Stack Developer | Husen Lokhandwala"
        />
        <meta
          property="og:title"
          content="Hi 👋, My name is Husen Lokhandwala I build things for web, click here to view more details about my work :)"
        />
        <meta property="og:image" content="/images/og_image.png" />
        <meta
          name="keywords"
          content="husen lokhandwala, About me, about husain, about husen, about husen lokhandwala, about husain lokhandwala, husain lokhandwala, hussain lokhandwala, husen, husain, hussain, lokhandwala, lokhandvala, react dev, react developer, mern stack developer, developer, frontend developer, frontend, frontend dev, lead react developer, lead frontend developer, mozayc, vma techlabs"
        />
      </Helmet>
      <div className="min-h-screen my-10 lg:my-16 lg:mx-8 w-full md:w-[60%]">
        <div className="my-5">
          <h1 className="font-bold text-[42px] mb-5">About Me</h1>
          {/* added name for better SEO */}
          <h2 className="hidden">Husen Lokhandwala</h2>
          <p className="text-dark-content">
            I am flexible, adaptable and a quick learner who can learn new
            things and write clean and efficient code using Data Structures and
            Algorithms.
            <br />
            <br />I have been working as a{" "}
            <span className="text-pink dark:text-light-content dark:font-semibold">
              REACT JS
            </span>{" "}
            developer since 2020 building features and improving quality of web
            application. I am a self-motivated and self-taught professional who
            loves to code.
          </p>
          <div className="my-8 text-center md:text-left">
            <a
              href="/Husen_MERN_Developer.pdf"
              download
              className="border px-10 py-2 text-white font-bold rounded-3xl bg-gradiant-color"
            >
              Download Resume
            </a>
          </div>
        </div>
        <div className="my-5">
          <h1 className="font-bold text-[42px] mb-5">Work Experience</h1>
          <div className="flex justify-between items-center">
            <div className="flex flex-col justify-center min-w-[50%]">
              <span className="text-[20px]">Senior React Developer</span>
              <div className="flex flex-col sm:flex-row items-start justify-between sm:items-center gap-x-10 text-light-content text-[12px] font-semibold">
                <span className="">VMA Techlabs</span>
                <span className="sm:self-end flex items-center gap-x-1">
                  <GoLocation /> Bangalore
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 justify-center">
              <span className="self-end w-fit bg-[#D7FFE0] text-[#018C0F] font-bold text-center rounded-3xl text-[9px] py-1 px-5">
                Full time
              </span>
              <span className="text-light-content text-[12px] font-semibold">
                Nov 2022 - Current
              </span>
            </div>
          </div>
          <hr className="my-3" />
          <div className="flex justify-between items-center">
            <div className="flex flex-col justify-center min-w-[50%]">
              <span className="text-[20px]">React Developer</span>
              <div className="flex flex-col sm:flex-row items-start justify-between sm:items-center gap-x-10 text-light-content text-[12px] font-semibold">
                <span className="">Mozayc</span>
                <span className="sm:self-end flex items-center gap-x-1">
                  <GoLocation /> Ahmedabad (WFH)
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 justify-center">
              <span className="self-end w-fit bg-[#D7FFE0] text-[#018C0F] font-bold text-center rounded-3xl text-[9px] py-1 px-5">
                Full time
              </span>
              <span className="text-light-content text-[12px] font-semibold">
                Apr 2020 - Oct 2022
              </span>
            </div>
          </div>
          <hr className="my-3" />
          <div className="flex justify-between items-center">
            <div className="flex flex-col justify-center min-w-[50%]">
              <span className="text-[20px]">Web Development</span>
              <div className="flex flex-col sm:flex-row items-start justify-between sm:items-center gap-x-10 text-light-content text-[12px] font-semibold">
                <span className="">Freelance</span>
                <span className="sm:self-end flex items-center gap-x-1">
                  <GoLocation /> WFH (GUJARAT)
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 justify-center">
              <span className="self-end w-fit bg-[#D7FFE0] text-[#018C0F] font-bold text-center rounded-3xl text-[9px] py-1 px-5">
                Full time
              </span>
              <span className="text-light-content text-[12px] font-semibold">
                Oct 2019 - Mar 2020
              </span>
            </div>
          </div>
          <hr className="mt-3" />
        </div>
        <div className="my-5">
          <h1 className="font-bold text-[42px] mb-5">Education</h1>
          <div className="flex justify-between items-center">
            <div className="flex flex-col justify-center">
              <span className="text-[20px]">
                Bachelor in Computer Applications
              </span>
              <span className="text-light-content text-[12px] font-semibold">
                Saurashtra University
              </span>
            </div>
            <div className="flex flex-col gap-y-2 justify-center">
              <span className="self-end w-fit bg-[#D7FFE0] text-[#018C0F] font-bold text-center rounded-3xl text-[9px] py-1 px-5">
                3 years
              </span>
              <span className="text-light-content text-[12px] font-semibold">
                Jul 2017 - Mar 2020
              </span>
            </div>
          </div>
          <hr className="mt-3" />
        </div>
      </div>
    </MainLayout>
  );
};

export default AboutMe;
